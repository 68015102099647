
.shareBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 120px;
}

.shareButton {
    background-color: transparent;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    border: white 1px solid;
    color: var(--base-text-color);
    font-size: 20px;
}
