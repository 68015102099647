.keyboard {
    box-sizing: border-box;
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 5px;
    width: 100vw;
    max-width: 400px;
    height: 150px;
    justify-content: stretch;
}

.keyboard_row {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: center;
    align-items: stretch;
}

.key {
    flex: 1 1 auto;
    margin: 1px;
    padding: 5px;
    background-color: #111;
    border-radius: 5px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.key > * {
    text-transform: capitalize;
    cursor: pointer;
}

.key.special_key {
    width: auto;
    min-width: 20px;
}

.key.correct {
    background-color: var(--correctKeyBackground);
    color: var(--correctKeyColor);
}

.key.misplaced {
    background-color: var(--misplacedKeyBackground);
    color: var(--misplacedKeyColor);
}

.key.incorrect {
    background-color: #222;
    color: #777;
}

.key.unknown {
    background-color: #111;
}

@media only screen and (min-width: 400px) {
    .keyboard {
        max-width: 400px;
    }
}

@media only screen and (min-width: 700px) {
    .key {
        min-width: 30px;
        font-size: 25px;
    }
    .key.special_key {
        width: auto;
    }
    .keyboard {
        max-width: 500px;
    }
}

@media only screen and (min-width: 900px) {
    .key {
        min-width: 30px;
        margin: 3px;
    }
    .key.special_key {
        width: auto;
    }
    .keyboard {
        max-width: 600px;
    }
}
