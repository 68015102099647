.wordgrid_container {
    position: relative;
}


.wordgrid {
    /* general settings */
    box-sizing: border-box;
    padding: 5px;
    background-color: var(--background-color);

    /* Grid Settings */
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: row;
    gap: 2px;
}

.character {
    /* grid settings */
    grid-row: auto;
    grid-column: auto;
    width: 100%;
    height: 100%;
    place-self: stretch center;

    /* text settings */
    text-align: center;
    vertical-align: middle;
    color: #ccc;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 30px;

    /* needed to center character */
    display: flex;
    justify-content: center;
    align-items: center;

    /* general settings */
    border-radius: 2px;
    box-shadow: #111a 1px 1px 2px;
    background-color: #222;
    border: rgb(90, 90, 90) 1px solid;
    box-sizing: border-box;
}

.character.active {
    border-color: #ccc;
    background-color: #222;
    color: #ddd;
}

.character.correct {
    background-color: var(--correctKeyBackground);
    color: var(--correctKeyColor);
}

.character.incorrect {
    background-color: #222;
    color: rgb(177, 177, 177);
}

.character.misplaced {
    background-color: var(--misplacedKeyBackground);
    color: var(--misplacedKeyColor);
}

.character.fail {
    background-color: var(--failKeyBackground);
    color: var(--failKeyColor);
}

.character.answer {
    border-color: red;
}
