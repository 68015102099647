:root {
    --base-text-color: #ddd;
    --background-color: #333;
    --misplacedKeyBackground: rgb(255, 189, 67);
    --misplacedKeyColor: #222;
    --correctKeyBackground: rgb(47, 156, 14);
    --correctKeyColor: #222;
    --failKeyBackground: rgb(185, 19, 19);
    --failKeyColor: #ddd;
}

html, body {
    margin: 0;
    padding: 0;
    color: var(--base-text-color);
    background-color: var(--background-color);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    overflow: hidden;
    user-select: none;
}

.game {
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: #333;
    padding-top: 70px;
    box-sizing: border-box;
}

header {
    padding: 10px;
    text-align: center;
    width: 100vw;
    height: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #ddd;
    background-color: #111111;
    box-shadow: rgba(0, 0, 0, 0.5) 1px 0 5px;
    text-shadow: #585858 1px 1px 2px;
    position: fixed;
    left: 0;
    top: 0;

}

@media only screen and (min-width: 700px) {
    header {
        width: 100%;
        text-align: left;
        padding-left: 20px;
    }
}
